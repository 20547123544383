import React from "react";
import APIEndPoints from "../../utils/APIEndPoints";
import { useAPIGet } from "../../services/APIService";

const Contact = () => {
  const apiContactUs= useAPIGet(
    "contactus",
    "contactus",
    `${APIEndPoints.GetContactus.url}`,
    {
      enabled: !!APIEndPoints?.GetContactus?.url,
      refetchOnWindowFocus: false,
      staleTime: 0,
    }
  );
  return (
    <section id="contact" className="contact">
      <div className="container" data-aos="fade-up">
        <div className="section-header">
          <h2>Contact</h2>
        </div>

        <div className="row gx-lg-0 gy-4">
          <div className="col-lg-4">
            <div className="info-container d-flex flex-column align-items-center justify-content-center">
              <div className="info-item d-flex">
                <i className="bi bi-geo-alt flex-shrink-0"></i>
                <div>
                  <h4>Location:</h4>
                  <p>{apiContactUs?.data?.data?.data?.address}</p>
                </div>
              </div>

              <div className="info-item d-flex">
                <i className="bi bi-envelope flex-shrink-0"></i>
                <div>
                  <h4>Email:</h4>
                  <p>{apiContactUs?.data?.data?.data?.email}</p>
                </div>
              </div>

              <div className="info-item d-flex">
                <i className="bi bi-phone flex-shrink-0"></i>
                <div>
                  <h4>Call:</h4>
                  <a href={`tel:${apiContactUs?.data?.data?.data?.mobile_one}`} style={{color:'white'}}>+91 {apiContactUs?.data?.data?.data?.mobile_one}</a><br/>
                  {apiContactUs?.data?.data?.data?.mobile_two ? <a href={`tel:${apiContactUs?.data?.data?.data?.mobile_two}`} style={{color:'white'}}>+91 {apiContactUs?.data?.data?.data?.mobile_two}</a>: null }
                </div>
              </div>

              <div className="info-item d-flex">
                <i className="bi bi-clock flex-shrink-0"></i>
                <div>
                  <h4>Open Hours:</h4>
                  <p>Mon-Sat: 11AM - 23PM</p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-8 px-3">
            <div id="map">
              <iframe src={apiContactUs?.data?.data?.data?.google_map.slice(5,apiContactUs?.data?.data?.data?.google_map.length-6)}
                width="100%" height="500" style={{ border: '0' }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe> 

            </div>
            {/* <form
              action="forms/contact.php"
              method="post"
              role="form"
              className="php-email-form"
            >
              <div className="row">
                <div className="col-md-6 form-group">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    id="name"
                    placeholder="Your Name"
                    required
                  />
                </div>
                <div className="col-md-6 form-group mt-3 mt-md-0">
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    id="email"
                    placeholder="Your Email"
                    required
                  />
                </div>
              </div>
              <div className="form-group mt-3">
                <input
                  type="text"
                  className="form-control"
                  name="subject"
                  id="subject"
                  placeholder="Subject"
                  required
                />
              </div>
              <div className="form-group mt-3">
                <textarea
                  className="form-control"
                  name="message"
                  rows="7"
                  placeholder="Message"
                  required
                ></textarea>
              </div>
              <div className="my-3">
                <div className="loading">Loading</div>
                <div className="error-message"></div>
                <div className="sent-message">
                  Your message has been sent. Thank you!
                </div>
              </div>
              <div className="text-center">
                <button type="submit">Send Message</button>
              </div>
            </form> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
