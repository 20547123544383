import React from "react";

export const DataContext = React.createContext({
  pageTitle: "Home",
  setPageTitle: () => {},
  activeNavbar: "Home",
  setActiveNavbar: () => {},
});

export const DataProvider = ({ children }) => {
  const [pageTitle, setPageTitle] = React.useState("");
  const [activeNavbar, setActiveNavbar] = React.useState("");

  return (
    <DataContext.Provider
      value={{
        pageTitle,
        setPageTitle,
        activeNavbar,
        setActiveNavbar,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
