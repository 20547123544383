import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { navbarList } from "../../utils/utils";
import APIEndPoints from "../../utils/APIEndPoints";
import { useAPIGet } from "../../services/APIService";
import "./Header.scss";

const mobileNavToogleLink = (event) => {
  event.preventDefault();

  const mobileNavShow = document.querySelector(".mobile-nav-show");
  const mobileNavHide = document.querySelector(".mobile-nav-hide");

  document.querySelector("body").classList.toggle("mobile-nav-active");
  mobileNavShow.classList.toggle("d-none");
  mobileNavHide.classList.toggle("d-none");
};

const mobileNavToogle = () => {
  const mobileNavShow = document.querySelector(".mobile-nav-show");
  const mobileNavHide = document.querySelector(".mobile-nav-hide");

  document.querySelector("body").classList.toggle("mobile-nav-active");
  mobileNavShow.classList.toggle("d-none");
  mobileNavHide.classList.toggle("d-none");
};

const navDropdowns = (event) => {
  if (document.querySelector(".mobile-nav-active")) {
    event.preventDefault();
    event.currentTarget.classList.toggle("active");
    event.currentTarget.nextElementSibling.classList.toggle("dropdown-active");

    let dropDownIndicator = event.currentTarget.querySelector(
      ".dropdown-indicator"
    );
    dropDownIndicator.classList.toggle("bi-chevron-up");
    dropDownIndicator.classList.toggle("bi-chevron-down");
  }
};

const Header = () => {
  const [headerList, setHeaderList] = useState(navbarList);

  const apiheaderProductList = useAPIGet(
    "headerProductList",
    "headerProductList",
    `${APIEndPoints.GetCategory.url}`,
    {
      enabled: !!APIEndPoints?.GetCategory?.url,
      refetchOnWindowFocus: false,
      staleTime: 0,
    }
  );
  const apiheaderOwnProductList = useAPIGet(
    "headerOwnProductList",
    "headerOwnProductList",
    `${APIEndPoints.GetOwnProductHeaderList.url}`,
    {
      enabled: !!APIEndPoints?.GetOwnProductHeaderList?.url,
      refetchOnWindowFocus: false,
      staleTime: 0,
    }
  );

  useEffect(() => {

    if (apiheaderProductList.isSuccess && apiheaderOwnProductList.isSuccess) {
      const ourProductHeaderList = apiheaderProductList?.data?.data?.data?.map((subitem) => {
        return {
          ...subitem,
          title: subitem?.name,
          navLink: "/category",
          itemURL: subitem?.slug_url,
          isParentLink: false,
        };
      });

      const ownProductHeaderList =apiheaderOwnProductList?.data?.data?.data?.map((subitem) => {
        return {
          ...subitem,
          title: subitem?.name,
          navLink: "/manufacture",
          itemURL: subitem?.slug_url,
          isParentLink: false,
        };
      });

      const newHeaderList = navbarList?.map((item) => {

        if (item?.id === 2 || item?.id === 3) {
          return {
            ...item, subLink: [...item?.id === 2 ? ourProductHeaderList : ownProductHeaderList, {
              id: 19,
              title: "All Products",
              navLink: item?.id === 3 ? "/manufacture" : "/category",
              itemURL: "all",
              name: 'All Product',
              isParentLink: false,
            }]
          };
        } else {
          return { ...item };
        }
      });
      setHeaderList(newHeaderList);
    }
  }, [apiheaderProductList.isSuccess, apiheaderOwnProductList.isSuccess])

  useEffect(() => {
    /**
     * Sticky Header on Scroll
     */
    const selectHeader = document.querySelector("#header");
    if (selectHeader) {
      let headerOffset = selectHeader.offsetTop;
      let nextElement = selectHeader.nextElementSibling;

      const headerFixed = () => {
        if (headerOffset - window.scrollY <= 0) {
          selectHeader.classList.add("sticked");
          if (nextElement) nextElement.classList.add("sticked-header-offset");
        } else {
          selectHeader.classList.remove("sticked");
          if (nextElement)
            nextElement.classList.remove("sticked-header-offset");
        }
      };
      headerFixed();
      document.addEventListener("scroll", headerFixed);
    }

    /**
     * Navbar links active state on scroll
     */
    let navbarlinks = document.querySelectorAll("#navbar a");

    function navbarlinksActive() {
      navbarlinks.forEach((navbarlink) => {
        if (!navbarlink.hash) return;

        let section = document.querySelector(navbarlink.hash);
        if (!section) return;

        let position = window.scrollY + 200;

        if (
          position >= section.offsetTop &&
          position <= section.offsetTop + section.offsetHeight
        ) {
          navbarlink.classList.add("active");
        } else {
          navbarlink.classList.remove("active");
        }
      });
    }
    navbarlinksActive();
    document.addEventListener("scroll", navbarlinksActive);

    /**
     * Scroll top button
     */
    const scrollTop = document.querySelector(".scroll-top");
    if (scrollTop) {
      const togglescrollTop = function () {
        window.scrollY > 100
          ? scrollTop.classList.add("active")
          : scrollTop.classList.remove("active");
      };
      togglescrollTop();
      document.addEventListener("scroll", togglescrollTop);
      scrollTop.addEventListener(
        "click",
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        })
      );
    }
  }, []);

  const navbarlinkClickHandler = () => {
    if (document.querySelector(".mobile-nav-active")) {
      mobileNavToogle();
    }
  };

  return (
    <>
      <section id="topbar" className="topbar d-flex align-items-center">
        <div className="container d-flex justify-content-center justify-content-md-between">
          <div className="contact-info d-flex align-items-center">
            <i className="bi bi-envelope d-flex align-items-center">
              <a className="headerMail" href="mailto:muthuvelagency22@gmail.com">
                muthuvelagency22@gmail.com
              </a>
            </i>
            <i className="bi bi-phone d-flex align-items-center ms-4">
              <a
                href="tel:9047029998"
              >
                <span>+91 90470 29998</span>
              </a>

            </i>
          </div>
          <div className="social-links d-none d-md-flex align-items-center">
            <a href="/" className="twitter">
              <i className="bi bi-twitter"></i>
            </a>
            <a href="/" className="facebook">
              <i className="bi bi-facebook"></i>
            </a>
            <a href="/" className="instagram">
              <i className="bi bi-instagram"></i>
            </a>
            <a href="/" className="linkedin">
              <i className="bi bi-linkedin"></i>
            </a>
          </div>
        </div>
      </section>
      <header id="header" className="header d-flex align-items-center">
        <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
          <a href="/" className="logo d-flex align-items-center">
            <h1>
              <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="180.000000pt"
                height="180.000000pt"
                viewBox="0 0 180.000000 180.000000"
                preserveAspectRatio="xMidYMid meet"
                style={{
                  width: "60px",
                  height: "60px",
                  position: "relative",
                  top: "-0.5rem",
                  left: "0.5rem",
                }}
              >
                <g
                  transform="translate(0.000000,180.000000) scale(0.100000,-0.100000)"
                  fill="#008374"
                  stroke="none"
                >
                  <path
                    d="M1458 1646 c-84 -30 -169 -77 -317 -177 -238 -160 -407 -319 -475
-448 -20 -39 -39 -71 -42 -71 -2 0 -70 45 -150 100 -80 55 -150 100 -155 100
-23 0 -33 -61 -33 -215 -1 -373 64 -576 213 -663 51 -30 137 -36 179 -13 l23
12 -26 21 c-43 34 -58 75 -63 181 -4 84 -3 97 11 97 9 0 53 -28 99 -62 76 -57
83 -67 88 -103 l5 -40 29 48 c30 50 56 126 56 161 0 16 15 30 53 51 81 43 87
36 70 -77 -41 -268 -58 -313 -128 -343 l-30 -13 30 -13 c49 -20 201 -17 262 5
73 27 141 81 185 146 69 103 106 274 207 955 45 303 46 298 -41 271 -115 -38
-369 -192 -535 -327 -35 -28 -63 -47 -63 -41 0 13 124 129 218 204 92 73 251
180 345 232 96 54 92 60 -15 22z"
                  />
                  <path
                    d="M303 1417 c-21 -31 -46 -83 -57 -120 -5 -15 -12 -17 -41 -11 -40 7
-41 5 -19 -39 23 -45 54 -77 76 -77 19 0 24 15 8 25 -19 12 -11 38 9 32 15 -3
21 1 23 17 4 30 22 26 26 -5 2 -20 8 -26 21 -22 26 7 38 -17 18 -32 -21 -15
-12 -25 22 -25 24 1 110 64 100 74 -2 2 -24 8 -48 13 l-44 8 -30 98 c-17 53
-33 97 -36 97 -3 0 -16 -15 -28 -33z"
                  />
                </g>
              </svg>
              uthuvel <span>Agency</span>
            </h1>
          </a>
          <nav id="navbar" className="navbar">
            <ul>
              {headerList.length !== 0 &&
                headerList.map((item) => {
                  if (item?.isParentLink) {
                    return (
                      <li
                        key={item.navLink + item.id}
                        className="dropdown navLinkCustom"
                      >
                        <NavLink
                          to={
                            item.itemURL
                              ? item.navLink + "/" + item.itemURL
                              : item.navLink
                          }
                          state={{
                            data: item,
                          }}
                          onClick={(event) => navDropdowns(event)}
                        >
                          <span>{item.title}</span>
                          <i className="bi bi-chevron-down dropdown-indicator"></i>
                        </NavLink>
                        <ul>
                          {item.subLink.length !== 0 &&
                            item.subLink.map((item2) => {
                              if (item2?.isParentLink) {
                                return (
                                  <li
                                    key={item2.navLink + item2.id}
                                    className="dropdown navLinkCustom"
                                  >
                                    <a
                                      href={item2.navLink}
                                      onClick={(event) => navDropdowns(event)}
                                    >
                                      <span>{item2.title}</span>
                                      <i className="bi bi-chevron-down dropdown-indicator"></i>
                                    </a>
                                    <ul>
                                      {item2.subLink.length !== 0 &&
                                        item2.subLink.map((item3) => {
                                          if (item3?.isParentLink) {
                                            return (
                                              <li
                                                key={item3.navLink + item3.id}
                                                className="dropdown navLinkCustom"
                                              >
                                                <a
                                                  href={item3.navLink}
                                                  onClick={(event) =>
                                                    navDropdowns(event)
                                                  }
                                                >
                                                  <span>{item3.title}</span>
                                                  <i className="bi bi-chevron-down dropdown-indicator"></i>
                                                </a>
                                                <ul>
                                                  <li>
                                                    <a href="/">None</a>
                                                  </li>
                                                </ul>
                                              </li>
                                            );
                                          } else {
                                            return (
                                              <li key={item3.navLink + item3.id}>
                                                <NavLink
                                                  to={
                                                    item2.itemURL
                                                      ? item3.navLink +
                                                      "/" +
                                                      item3.itemURL
                                                      : item3.navLink
                                                  }
                                                  state={{
                                                    data: item3,
                                                  }}
                                                  onClick={() =>
                                                    navbarlinkClickHandler()
                                                  }
                                                >
                                                  <p>{item3.title}</p>
                                                </NavLink>
                                              </li>
                                            );
                                          }
                                        })}
                                    </ul>
                                  </li>
                                );
                              } else {
                                if (item2.itemURL === "all") {
                                  return (
                                    <>
                                      <hr />
                                      <li key={item2.navLink + item2.id} className="childNavAll">
                                        {/* <a href={`${item2.navLink}`}>
                                      {item2.title}
                                    </a> */}
                                        <NavLink
                                          to={
                                            item2.itemURL
                                              ? item2.navLink + "/" + item2.itemURL
                                              : item2.navLink
                                          }
                                          state={{
                                            data: item2,
                                          }}
                                          onClick={() => navbarlinkClickHandler()}
                                        >
                                          <p>{item2.title}</p>
                                        </NavLink>
                                      </li>
                                    </>
                                  )
                                }
                                else {
                                  return (
                                    <li key={item2.navLink + item2.id} className="childNav">
                                      {/* <a href={`${item2.navLink}`}>
                                            {item2.title}
                                          </a> */}
                                      <NavLink
                                        to={
                                          item2.itemURL
                                            ? item2.navLink + "/" + item2.itemURL
                                            : item2.navLink
                                        }
                                        state={{
                                          data: item2,
                                        }}
                                        onClick={() => navbarlinkClickHandler()}
                                      >
                                        <p>{item2.title}</p>
                                      </NavLink>
                                    </li>
                                  )
                                }
                              }
                            })}
                        </ul>
                      </li>
                    );
                  } else {
                    return (
                      <li key={item.title}>
                        <NavLink
                          to={
                            item.itemURL
                              ? item.navLink + "/" + item.itemURL
                              : item.navLink
                          }
                          state={{ data: item }}
                          onClick={() => navbarlinkClickHandler()}
                        >
                          <p>{item.title}</p>
                        </NavLink>
                      </li>
                    );
                  }
                })}
            </ul>
          </nav>
          <i
            className="mobile-nav-toggle mobile-nav-show bi bi-list"
            onClick={(event) => mobileNavToogleLink(event)}
          ></i>
          <i
            className="mobile-nav-toggle mobile-nav-hide d-none bi bi-x"
            onClick={(event) => mobileNavToogleLink(event)}
          ></i>
        </div>
      </header>
    </>
  );
};

export default Header;
