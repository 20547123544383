import * as React from "react"
const StatsCounterSvgComponent1 = (props) => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="792" 
    height="398.62458"
    data-name="Layer 1">
    <path
      fill="#2f2e41"
      d="M635.056 79.822h-58a4.505 4.505 0 0 1-4.5-4.5v-21a33.5 33.5 0 1 1 67 0v21a4.505 4.505 0 0 1-4.5 4.5Z"
    />
    <path
      fill="#a0616a"
      d="m544.851 385.91-11.743-3.522 7.994-46.97 17.332 5.197-13.583 45.295z"
    />
    <path
      fill="#2f2e41"
      d="m544.432 398.191-37.864-11.354.143-.48a15.386 15.386 0 0 1 19.157-10.318h.001l23.127 6.935Z"
    />
    <path
      fill="#a0616a"
      d="M681.636 386.307h-12.26l-5.832-47.288 18.094.001-.002 47.287z"
    />
    <path
      fill="#2f2e41"
      d="m684.762 398.191-39.53-.001v-.5a15.386 15.386 0 0 1 15.386-15.386h24.145Z"/>
    <circle cx="607.403" cy="55.054" r="24.561" fill="#a0616a" />
    <path
      fill="#2f2e41"
      d="M578.109 358.462a5.216 5.216 0 0 1-1.312-.167l-49.22-12.798a5.285 5.285 0 0 1-3.622-6.957c29.841-80.26 54.276-149.493 51-183.796a66.141 66.141 0 0 1-6.899-30.014l.35-34.68a6.792 6.792 0 0 1 8.294-6.556l55.066 12.467.312 1.039a72.25 72.25 0 0 1-1.262 45.078c32.462 29.307 54.229 125.521 66.784 201.2a5.312 5.312 0 0 1-3.299 5.794l-.155.038-38.608 3.816a5.284 5.284 0 0 1-6.894-3.423l-33.172-109.466-32.26 114.576a5.321 5.321 0 0 1-5.103 3.85ZM630.749 55.822h-32.427a2.504 2.504 0 0 1-2.48-2.19l-.42-3.36a1.5 1.5 0 0 0-2.848-.447l-2.126 4.554a2.51 2.51 0 0 1-2.265 1.443h-4.926a2.5 2.5 0 0 1-2.489-2.739l1.942-20.286a2.52 2.52 0 0 1 1.43-2.036c14.85-6.933 29.909-6.924 44.76.026a2.504 2.504 0 0 1 1.414 1.92l2.91 20.26a2.5 2.5 0 0 1-2.476 2.855Z"
    />
    <path
      fill="#ccc"
      d="m557.583 364.776-307.917-25.85a8.51 8.51 0 0 1-7.76-9.181l11.63-138.513a8.51 8.51 0 0 1 9.18-7.759l307.917 25.85a8.51 8.51 0 0 1 7.76 9.181l-11.629 138.513a8.51 8.51 0 0 1-9.181 7.759Z"
    />
    <path
      fill="#fff"
      d="m553.353 355.39-297.952-25.014a4.505 4.505 0 0 1-4.107-4.86l10.791-128.548a4.505 4.505 0 0 1 4.86-4.108l297.953 25.013a4.505 4.505 0 0 1 4.108 4.86l-10.792 128.548a4.505 4.505 0 0 1-4.86 4.108Z"
    />
    <path
      fill="#3f3d56"
      d="M476.605 307.133q.028.003.057.003a38.66 38.66 0 0 0 6.456-76.893.882.882 0 0 0-.683.182.873.873 0 0 0-.33.617l-6.308 75.137a.885.885 0 0 0 .808.954Z"
    />
    <path
      fill="#e6e6e6"
      d="M451.054 240.413a1.138 1.138 0 0 1 .772.399l23.964 28.356a1.126 1.126 0 0 1 .264.826l-3.018 35.954a1.122 1.122 0 0 1-.425.792 1.14 1.14 0 0 1-.876.235 38.913 38.913 0 0 1-21.571-66.24 1.14 1.14 0 0 1 .848-.324Z"
    />
    <path
      fill="#008374"
      d="M478.304 229.584a1.143 1.143 0 0 1 .752.373 1.123 1.123 0 0 1 .288.854l-2.724 32.439a1.133 1.133 0 0 1-1.995.637l-20.911-24.744a1.136 1.136 0 0 1 .18-1.637 38.994 38.994 0 0 1 24.34-7.925c.024 0 .047.001.07.003ZM459.919 321.597a6.944 6.944 0 1 1-6.339-7.5 6.952 6.952 0 0 1 6.339 7.5Z"
    />
    <path
      fill="#3f3d56"
      d="M479.688 323.256a6.944 6.944 0 1 1-6.338-7.5 6.952 6.952 0 0 1 6.338 7.5Z"
    />
    <path
      fill="#e6e6e6"
      d="M499.458 324.916a6.944 6.944 0 1 1-6.339-7.5 6.952 6.952 0 0 1 6.339 7.5ZM376.026 240.607l-63.487-5.33a4.725 4.725 0 1 0-.79 9.418l63.486 5.33a4.725 4.725 0 1 0 .79-9.418ZM373.866 266.337l-63.487-5.33a4.722 4.722 0 0 0-2.689 8.836 4.662 4.662 0 0 0 1.898.581l63.487 5.33a4.725 4.725 0 1 0 .79-9.417ZM371.706 292.066l-63.487-5.33a4.725 4.725 0 1 0-.79 9.418l63.486 5.33a4.725 4.725 0 1 0 .79-9.418Z"
    />
    <path
      fill="#008374"
      d="m357.959 239.09-45.42-3.812a4.725 4.725 0 1 0-.79 9.417l44.473 3.733a5.38 5.38 0 0 0 1.737-9.337ZM334.823 263.06l-24.444-2.053a4.722 4.722 0 0 0-2.689 8.836l22.451 1.885a5.382 5.382 0 0 0 5.811-4.911 5.27 5.27 0 0 0-1.13-3.758ZM366.962 291.668l-58.743-4.931a4.725 4.725 0 1 0-.79 9.417l59.121 4.963a5.368 5.368 0 0 0 .412-9.449Z"
    />
    <path
      fill="#a0616a"
      d="M530.99 215.842a10.743 10.743 0 0 0 7.445-14.695l34.621-90.825-17.673-4.353-31.804 89.743a10.8 10.8 0 0 0 7.412 20.13Z"
    />
    <path
      fill="#2f2e41"
      d="M574.719 128.631a5.319 5.319 0 0 1-2.254-.5l-22.034-10.314a5.318 5.318 0 0 1-2.179-7.752l13.39-20.223a13.584 13.584 0 0 1 7.777-7.035 13.88 13.88 0 0 1 17.291 18.937l-6.898 23.095a5.317 5.317 0 0 1-5.093 3.792Z"
    />
    <path
      fill="#a0616a"
      d="M560.503 217.39a10.743 10.743 0 0 0 10.152-12.972l56.196-80.097-16.795-9-52.922 80.885a10.8 10.8 0 0 0 3.369 21.185Z"
    />
    <path
      fill="#2f2e41"
      d="M621.338 140.413a5.313 5.313 0 0 1-3.144-1.03l-19.616-14.39a5.316 5.316 0 0 1-.636-8.025L615 99.725a13.584 13.584 0 0 1 8.993-5.396 13.88 13.88 0 0 1 13.292 21.931l-11.244 21.318a5.32 5.32 0 0 1-4.702 2.835Z"
    />
    <path
      fill="#ffb8b8"
      d="M265.569 198.182a10.056 10.056 0 0 1-5.277-14.488l-23.36-27.044 18.415-2.396 19.36 26.048a10.11 10.11 0 0 1-9.138 17.88ZM133.905 203.897a10.056 10.056 0 0 1 2.362-15.238l-7.425-34.955 17.288 6.78 4.4 32.154a10.11 10.11 0 0 1-16.625 11.259ZM217.569 386.069l12.26-.001 5.832-47.288-18.094.001.002 47.288z"
    />
    <path
      fill="#2f2e41"
      d="M214.442 382.066H238.586a15.386 15.386 0 0 1 15.387 15.385v.5l-39.53.002Z"
    />
    <path
      fill="#ffb8b8"
      d="m157.569 386.069 12.26-.001 5.832-47.288-18.094.001.002 47.288z"
    />
    <path
      fill="#2f2e41"
      d="M154.442 382.066H178.586a15.386 15.386 0 0 1 15.387 15.385v.5l-39.53.002ZM216.114 361.38a4.526 4.526 0 0 1-4.47-3.957l-12.538-103.33a1.5 1.5 0 0 0-2.96-.11l-19.935 101.41a4.51 4.51 0 0 1-4.416 3.631H157.16a4.5 4.5 0 0 1-4.5-4.585l2.93-156.202.238-.143c21.348-12.71 46.51-13.543 76.926-2.54l.32.116 4.89 160.379a4.514 4.514 0 0 1-4.319 4.633l-17.355.695-.177.003Z"
    />
    <circle cx="199.408" cy="30.461" r="24.561" fill="#ffb8b8" />
    <path
      fill="#008374"
      d="M256.253 178.93a4.505 4.505 0 0 1-3.528-1.009l-25.185-21.134a46.374 46.374 0 0 1-16.024-28.44l-5.665-36.602a14.497 14.497 0 1 1 27.017-10.52l11.986 57.51 19.132 25.968a4.515 4.515 0 0 1 .119 5.17l-4.746 7.101a4.506 4.506 0 0 1-2.09 1.686 4.454 4.454 0 0 1-1.016.27ZM134.013 180.797a4.505 4.505 0 0 1-1.982-3.088l-4.941-32.504a46.373 46.373 0 0 1 6.763-31.936l19.932-31.217a14.497 14.497 0 1 1 27.23 9.954l-29.001 51.089-2.797 32.133a4.515 4.515 0 0 1-3.326 3.959l-8.255 2.195a4.506 4.506 0 0 1-2.683-.116 4.455 4.455 0 0 1-.94-.47Z"
    />
    <path
      fill="#008374"
      d="M226.273 74.078a41.823 41.823 0 0 0-33.537-13.56q-.616.039-1.227.087h-.001a41.734 41.734 0 0 0-30.16 16.646 42.362 42.362 0 0 0-7.525 34.145 132.361 132.361 0 0 1-3.496 67.876l-6.589 20.459a4.499 4.499 0 0 0 4.042 5.872l33.685 1.81 8.62-14.889v15.353l40.941 2.201c.082.005.163.007.243.007a4.5 4.5 0 0 0 4.484-4.861l-4.627-57.382 5.804-38.973a42.09 42.09 0 0 0-10.657-34.79Z"
    />
    <path
      fill="#2f2e41"
      d="M182.338 53.908c2.274 1.309 11.263 2.176 11.903-.368l.014-.056c.445-1.864.075-3.81-.053-5.723s.06-4.013 1.362-5.42c2.428-2.62 6.748-1.231 10.259-1.894a8.664 8.664 0 0 0 6.74-9.253c-.05-.397-.129-.795-.217-1.193a6.673 6.673 0 0 1 6.927-8.081c3.548.203 7.378 1.278 10.314-1.35 2.27-2.033 2.824-7.323 1.826-10.202C229.737 5.53 219 2.997 214.311 1.605a40.62 40.62 0 0 0-34.43 5.65c-2.961 2.086-5.727 4.709-6.989 8.105s-.653 7.68 2.193 9.922a18.332 18.332 0 0 0-4.246 17.806c1.405 4.53 8.114 8.872 11.5 10.82Z"
    />
    <path fill="#ccc" d="M791 398.625H1a1 1 0 0 1 0-2h790a1 1 0 0 1 0 2Z" />
    <path
      fill="#f2f2f2"
      d="M714.713 218.92a11.358 11.358 0 1 1 10.41-12.233 11.358 11.358 0 0 1-10.41 12.233Zm-.14-1.742a9.61 9.61 0 1 0-10.351-8.807 9.61 9.61 0 0 0 10.351 8.807Z"
    />
    <circle cx="753.822" cy="182.788" r="6.989" fill="#f2f2f2" />
    <circle cx="788.177" cy="160.829" r="3.671" fill="#f2f2f2" />
    <path
      fill="#f2f2f2"
      d="M401.341 137.027a11.358 11.358 0 1 1-3.454 15.686 11.37 11.37 0 0 1 3.454-15.686Zm11.291 17.668a9.61 9.61 0 1 0-13.273-2.923 9.621 9.621 0 0 0 13.273 2.923Z"
    />
    <circle cx="403.035" cy="99.718" r="6.989" fill="#f2f2f2" />
    <circle cx="399.764" cy="59.076" r="3.671" fill="#f2f2f2" />
    <path
      fill="#f2f2f2"
      d="M92.07 265.707a11.358 11.358 0 1 1 15.78 3 11.37 11.37 0 0 1-15.78-3Zm17.335-11.797a9.61 9.61 0 1 0-2.538 13.352 9.621 9.621 0 0 0 2.538-13.352Z"
    />
    <circle cx="54.728" cy="265.092" r="6.989" fill="#f2f2f2" />
    <circle cx="14.197" cy="269.537" r="3.671" fill="#f2f2f2" />
  </svg>
)
export default StatsCounterSvgComponent1
