import React from "react";
import aboutImage1 from '../../assets/images/about-3.jpg'


const About = () => {
  return (
    <section id="aboutus" className="about">
      <div className="container" data-aos="fade-up">
        <div className="section-header">
          <h2>About Us</h2>
        </div>

        <div className="row gy-4">
          <div className="col-lg-6">
            <h3>Muthuvel Agency</h3>
            <img
              src={aboutImage1}
              className="img-fluid rounded-4 mb-4"
              alt="about us"
            />
          
          </div>
          <div className="col-lg-6">
            <div className="content ps-0 ps-lg-5">
              <p className="fst-italic">
                We are Multi Brand Authorized Dealership Shop.
                Now a Days UPS Systems, Batteries and Solar Panels requirement in Every Home, 
                Commercial, and Automobiles Purpose. In Automobile Industry EV's are Revolutionizing day by day.
                And Every Home and Commercial Required Solar Power System to Help reduce Electric Bill and also 
                Solar Power System is a Renewable Energy to Support natural Eco System. Our Vision to Deliver our Products with Authorized
                Brand to serve  Better Reliablity and Best Solution for your Requirement including
                with Best Price. We Offer to Deliver our Products with Selling, Consultation, and Installation
                Based on Customer Requirments. And We providing Next Generation Services such as Home Automation and Automatic Waterlevel Controller.
              </p>

              <ul>
                <li>
                  <i className="bi bi-check-circle-fill"></i> 
                  Solar Pannels
                </li>
                <li>
                  <i className="bi bi-check-circle-fill"></i> 
                  Solar Pumps
                </li>
                <li>
                  <i className="bi bi-check-circle-fill"></i> 
                  Home Inverter System and Batteries
                </li>
                <li>
                  <i className="bi bi-check-circle-fill"></i> 
                  Commercial Inverter System and Batteries
                </li>
                <li>
                  <i className="bi bi-check-circle-fill"></i> 
                  Automotive Batteries
                </li>
                <li>
                  <i className="bi bi-check-circle-fill"></i> 
                  EV Batteries
                </li>
                <li>
                  <i className="bi bi-check-circle-fill"></i> 
                  Home Automation
                </li>
                <li>
                  <i className="bi bi-check-circle-fill"></i> 
                  Automatic Water Level Controller
                </li>
              </ul>
              
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
