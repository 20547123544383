import React from "react";
import APIEndPoints from "../../utils/APIEndPoints";
import { useAPIGet } from "../../services/APIService";

const Footer = () => {
  const apiContactUs= useAPIGet(
    "contactus",
    "contactus",
    `${APIEndPoints.GetContactus.url}`,
    {
      enabled: !!APIEndPoints?.GetContactus?.url,
      refetchOnWindowFocus: false,
      staleTime: 0,
    }
  );
  return (
    <footer id="footer" className="footer">
      <div className="container">
        <div className="row gy-4">
          <div className="col-lg-5 col-md-12 footer-info">
            <a href="/" className="logo d-flex align-items-center">
            <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="180.000000pt"
                height="180.000000pt"
                viewBox="0 0 180.000000 180.000000"
                preserveAspectRatio="xMidYMid meet"
                style={{width:'60px', height:'60px', position:'relative', top:'-0.5rem',left:'0.5rem'}}
              >
                <g
                  transform="translate(0.000000,180.000000) scale(0.100000,-0.100000)"
                  fill="white"
                  stroke="none"
                >
                  <path
                    d="M1458 1646 c-84 -30 -169 -77 -317 -177 -238 -160 -407 -319 -475
-448 -20 -39 -39 -71 -42 -71 -2 0 -70 45 -150 100 -80 55 -150 100 -155 100
-23 0 -33 -61 -33 -215 -1 -373 64 -576 213 -663 51 -30 137 -36 179 -13 l23
12 -26 21 c-43 34 -58 75 -63 181 -4 84 -3 97 11 97 9 0 53 -28 99 -62 76 -57
83 -67 88 -103 l5 -40 29 48 c30 50 56 126 56 161 0 16 15 30 53 51 81 43 87
36 70 -77 -41 -268 -58 -313 -128 -343 l-30 -13 30 -13 c49 -20 201 -17 262 5
73 27 141 81 185 146 69 103 106 274 207 955 45 303 46 298 -41 271 -115 -38
-369 -192 -535 -327 -35 -28 -63 -47 -63 -41 0 13 124 129 218 204 92 73 251
180 345 232 96 54 92 60 -15 22z"
                  />
                  <path
                    d="M303 1417 c-21 -31 -46 -83 -57 -120 -5 -15 -12 -17 -41 -11 -40 7
-41 5 -19 -39 23 -45 54 -77 76 -77 19 0 24 15 8 25 -19 12 -11 38 9 32 15 -3
21 1 23 17 4 30 22 26 26 -5 2 -20 8 -26 21 -22 26 7 38 -17 18 -32 -21 -15
-12 -25 22 -25 24 1 110 64 100 74 -2 2 -24 8 -48 13 l-44 8 -30 98 c-17 53
-33 97 -36 97 -3 0 -16 -15 -28 -33z"
                  />
                </g>
              </svg><span>uthuvel</span><label>&nbsp;Agency</label>
            </a>

            {/* <p>
              Cras fermentum odio eu feugiat lide par naso tierra. Justo eget
              nada terra videa magna derita valies darta donna mare fermentum
              iaculis eu non diam phasellus.
            </p> */}
            <div className="social-links d-flex mt-4">
              <a href="/" className="twitter">
                <i className="bi bi-twitter"></i>
              </a>
              <a href="/" className="facebook">
                <i className="bi bi-facebook"></i>
              </a>
              <a href="/" className="instagram">
                <i className="bi bi-instagram"></i>
              </a>
              <a href="/" className="linkedin">
                <i className="bi bi-linkedin"></i>
              </a>
            </div>
          </div>

          <div className="col-lg-2 col-6 footer-links">
            <h4>Useful Links</h4>
            <ul>
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/about">About us</a>
              </li>
              <li>
                <a href="/">Services</a>
              </li>
              <li>
                <a href="/">Terms of service</a>
              </li>
              <li>
                <a href="/">Privacy policy</a>
              </li>
            </ul>
            <br />
          </div>

          <div className="col-lg-2 col-6 footer-links">
            <h4>Our Services</h4>
            <ul>
              <li>
                <a href="/">Batteries</a>
              </li>
              <li>
                <a href="/">Solar Power Products</a>
              </li>
              <li>
                <a href="/">Solar Pumps</a>
              </li>
              {/* <li>
                <a href="/">Marketing</a>
              </li>
              <li>
                <a href="/">Graphic Design</a>
              </li> */}
            </ul>
          </div>

          <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
            <h4>Contact Us</h4>
            <p>
              {apiContactUs?.data?.data?.data?.address}
              <br />
              India <br />
              <br />
              <strong>Phone:</strong><br/>
              <a href={`tel:${apiContactUs?.data?.data?.data?.mobile_one}`} style={{color:'white'}}>+91 {apiContactUs?.data?.data?.data?.mobile_one}</a><br/>
                  {apiContactUs?.data?.data?.data?.mobile_two ? <a href={`tel:${apiContactUs?.data?.data?.data?.mobile_two}`} style={{color:'white'}}>+91 {apiContactUs?.data?.data?.data?.mobile_two}</a>: null }
                  <br />
              <br />
              <strong>Email:</strong> <br/>
              {apiContactUs?.data?.data?.data?.email}
              <br />
            </p>
          </div>
        </div>
      </div>

      <div className="container mt-4">
        <div className="copyright">
          &copy; Copyright 2023
          <strong>
            <span> Muthuvel Agency</span>
          </strong>
          . All Rights Reserved
        </div>
        <div className="credits">
          Designed by <a href="/">Adhiinc</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
