import React from "react";
import { Helmet } from "react-helmet-async";

const HelmetComp = ({ title, data }) => {
    return (
        <>
            <Helmet>
                <title>{data?.meta_title || title}</title>
                <meta content={data?.meta_title || title} name="title" />
                <meta content={data?.meta_description || ''} name="description" />
                <meta content={data?.meta_keywords || ''} name="keywords" />

                <meta property="og:title" content={data?.meta_title || title}/>
                <meta property="og:type" content="website" />
                <meta property="og:URL" content="https://muthuvelagency.netlify.app/" />
                <meta property="og:image" content={data?.meta_images ||"https://muthuvelagency.netlify.app/"} />
                <meta property="og:description" content={data?.meta_description || ''}  />
            </Helmet>
        </>
    )
}

export default HelmetComp;